.App {
  text-align: center;
  background-image: url("Assets/oyster-growth.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 4000px;
  background-color: white;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  height: 100vh;
  width: 100vw;
}

.nav {
  position: relative;
  @media (min-width: 840px) {
    top: 0;
    left: 0;
    right: 0;
    height: 5vh;
    padding-left: 3vw;
    padding-right: 3vw;
    background-color: transparent;
    z-index: 1;
    background-size: cover;
    .background {
      position: fixed;
      padding-top: 5vh;
      background-color: transparent;
      top: 0;
      left: 0;
      right: 0;
      zIndex:1;
      background-size: cover;
    }
    .container {
      display: flex;
      text-align: center;
      padding-left: 3vw;
      padding-right: 3vw;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .title-container {
        text-align: center;
        position: absolute;
        left: 50%;
        top: 5vh;
        padding-top: 1vh;
        transform: translate(-50%, -50%);
        max-width: 30vw;
        .title {
          font-size: 80px;
          font-family: 'East Sea Dokdo';
          white-space: nowrap;
        }
      }
    }
  }
  @media (max-width: 840px) {
    top: 0;
    left: 0;
    right: 0;
    height: 5vh;
    padding-left: 3vw;
    padding-right: 3vw;
    background-color: transparent;
    z-index: 1;
    background-size: cover;
    .background {
      position: fixed;
      padding-top: 5vh;
      background-color: transparent;
      top: 0;
      left: 0;
      right: 0;
      z-index:1;
      background-size: cover;
    }
    .container {
      display: flex;
      text-align: center;
      padding-top: 3vh;
      padding-bottom: 3vh;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .title-container {
        text-align: center;
        position: absolute;
        left: 50%;
        top: 7vh;
        padding-top: 1vh;
        transform: translate(-50%, -50%);
        max-width: 90vw;
        .title {
          font-size: 80px;
          font-family: 'East Sea Dokdo';
          white-space: nowrap;
        }
      }
    }
  }

}

.PaperbgOpaque {
  @media (max-width: 840px) {
    padding: 5px;
  }
  @media (min-width: 840px) {
    padding: 30px;
  }
  position: absolute;
  height: 87.1vh;
  width: 92vw;
  backface-visibility: hidden;
  overflow-y:auto;
  background-color: transparent;
}

.footer {
  background-color: transparent;
  height: 15vh;
  position: fixed;
  bottom: 0;
  margin: 0 auto;
  padding: 10px;
  width: 100vw;
  .item {
    margin: 0 auto;
    display: table;
    @media (min-width: 840px) {
      transform: translateX(-50%);
    }
    @media (max-width: 840px) {
      transform: translateX(-30%);
    }
    .social {
      justify-content: space-between;
      box-sizing: border-box;
      padding: 10px;
      margin: 5px;
    }
  }
}
.Paperbg {
  padding: 30px;
  position: absolute;
  margin: 20px;
  height: 87.1vh;
  width: 92vw;
  backface-visibility: hidden;
  overflow-y:auto;
  opacity: 0.9;
}

.link {
  text-decoration: underline;
  color: yellow;
  cursor: pointer;
}

.page-content {
  @media (min-width: 840px) {
    top: 10vh;
    bottom: 15vh;
    background-color: transparent;
    overflow-y: scroll;
    position: fixed;
  }
  @media (max-width: 840px) {
    top: 17vh;
    @media (min-width: 345px) {

      top: 22vh;
    }
    bottom: 15vh;
    background-color: transparent;
    overflow-y: scroll;
    position: fixed;
  }
}

.wrapper {
  border-radius: 5px;
  margin-bottom: 2em;
}

.wrapper > div {
  background-color: transparent;
  border-radius: 5px;
  padding: 10px;
  border: 5px solid transparent;

  box-shadow: 10px 10px transparent;
  color: #fff;
  font-weight: bold;
  &:hover {
    top: 0;
    background: linear-gradient(-45deg, #86E3CE, #D0E6A5, #FFDD94, #FA897B, #CCABD8);;
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    border: 5px solid black;
    box-shadow: 10px 10px #888888;
  }
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex > * {
  flex: 1 1 250px;
    margin: 10px;
}

.homepage {
  width: 90vw;
  .content {
    display: flex;
    flex-direction: column;
  }
}


.homepage-bio {
  .title {
    font-size: 5rem;
    color: white;
    text-shadow:
            3px 3px 0 #000,
            -1px -1px 0 #000,
            1px -1px 0 #000,
            -1px 1px 0 #000,
            1px 1px 0 #000;

  }
  p {
    font-size: 2rem;
    color: white;
    text-shadow:
            3px 3px 0 #000,
            -1px -1px 0 #000,
            1px -1px 0 #000,
            -1px 1px 0 #000,
            1px 1px 0 #000;
  }

}

.showcase-project-container {
  background: linear-gradient(-45deg, #86E3CE, #D0E6A5, #FFDD94, #FA897B, #CCABD8);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  border: 5px solid black;
  align-self: center;
  //transform: translateX(50%);
  box-shadow: 10px 10px #888888;

  @media (max-width: 840px) {
    border-radius: 5px;

  }
  @media (min-width: 840px) {
    padding: 1rem;
    border-radius: 5px;
  }

  .title {
    @media (max-width: 840px) {
      padding-top: 3rem;
      padding-left: 3rem;
    }
    @media (min-width: 840px) {
      padding-top: 1rem;
    }
    display: table;
    margin: 0 auto;
    font-size: 3rem;
    color: white;
    text-shadow:
            3px 3px 0 #000,
            -1px -1px 0 #000,
            1px -1px 0 #000,
            -1px 1px 0 #000,
            1px 1px 0 #000;

  }
}

.showcase-project {
  height: auto;
  width: 40vw;
  display: flex;
  flex-direction: column;
  margin: 2rem;
  img {
    object-fit: scale-down;
    height: 25vh;
    width: auto;
    cursor: pointer;
  }
  .title-link {
    color: white;
    text-shadow:
            3px 3px 0 #000,
            -1px -1px 0 #000,
            1px -1px 0 #000,
            -1px 1px 0 #000,
            1px 1px 0 #000;
    font-size: 3rem;
    font-weight: bolder;
    cursor: pointer;
  }
  p {
    margin-top: -2px;
    margin-bottom: -2px;
    font-size: 2rem;
    color: white;
    text-shadow:
            3px 3px 0 #000,
            -1px -1px 0 #000,
            1px -1px 0 #000,
            -1px 1px 0 #000,
            1px 1px 0 #000;
  }
  @media (max-width: 840px) {
  .short-description {
    width: 70vw;
    padding: 1rem 0;
  }
  .long-description {
    width: 70vw;
  }
  }
}

.project {
  @media (max-width: 840px) {
    height: 60vh;
    width: 55vw;
  }
  @media (min-width: 840px) {
    height: 50vh;
    width: 50vw;
  }
  display: flex;
  flex-direction: column;
  background-color: transparent;

  img {
    object-fit: scale-down;
    height: 25vh;
    width: auto;
    cursor: pointer;
  }
  .title-link {
    color: white;
    text-shadow:
            3px 3px 0 #000,
            -1px -1px 0 #000,
            1px -1px 0 #000,
            -1px 1px 0 #000,
            1px 1px 0 #000;
    font-size: 3rem;
    font-weight: bolder;
    cursor: pointer;
  }
  p {
    font-size: 1rem;
    color: white;
    text-shadow:
            3px 3px 0 #000,
            -1px -1px 0 #000,
            1px -1px 0 #000,
            -1px 1px 0 #000,
            1px 1px 0 #000;
  }
}


@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.NavButton {

}

.cardwhite {
  background-color: white;
  padding: 100px;
}

.body {
  text-align: left;
  display: flex;
  flex-direction: row;
  margin: 10px;
  h2 {
    margin: 0 0;
    font-size: 4rem;
    color: white;
    text-shadow:
            3px 3px 0 #000,
            -1px -1px 0 #000,
            1px -1px 0 #000,
            -1px 1px 0 #000,
            1px 1px 0 #000;
  }
  h3 {
    margin: 0 0;
    font-size: 3rem;
    color: white;
    text-shadow:
            3px 3px 0 #000,
            -1px -1px 0 #000,
            1px -1px 0 #000,
            -1px 1px 0 #000,
            1px 1px 0 #000;
  }
  h4 {
    margin: 0 0;
    font-size: 2rem;
    color: white;
    text-shadow:
            3px 3px 0 #000,
            -1px -1px 0 #000,
            1px -1px 0 #000,
            -1px 1px 0 #000,
            1px 1px 0 #000;
  }
  ul {
    list-style: none; /* Remove default bullets */
  }

  ul li::before {
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: red; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
  }
  li {
    font-size: 1rem;
    color: white;
    text-shadow:
            3px 3px 0 #000,
            -1px -1px 0 #000,
            1px -1px 0 #000,
            -1px 1px 0 #000,
            1px 1px 0 #000;
  }
  a {
    font-size: 1rem;
    color: white;
    text-shadow:
            3px 3px 0 #000,
            -1px -1px 0 #000,
            1px -1px 0 #000,
            -1px 1px 0 #000,
            1px 1px 0 #000;
  }
}

.flex-column {
  flex-direction: column;
}



.redacted {
  color: black;
  background-color: black;
  white-space:nowrap;
  -moz-transform: rotate(.8deg) skewx(-12deg);
  -moz-box-shadow:3px 0 2px #444;
  background: -moz-linear-gradient(180deg, #000, #222);
  word-wrap: break-word;
  overflow-wrap: break-word;
}

/* Add a few more selectors with slightly varying styles */
.redacted:first-child {
  -moz-transform: rotate(-.8deg);
}
.redacted:first-child + .redacted {
  -moz-transform: rotate(3deg);
}

/* Highlight effect */
.redacted::-moz-selection {
  background:#e6ff3f;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
